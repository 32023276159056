import {Grid, Typography} from '@mui/material';
import {Container} from '@mui/system';
import RivcreteHeader from '@/components/RivcreteHeader';

const NotFound = () : JSX.Element => {
    return (
        <Container maxWidth="md" sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 500,
            flexGrow: 1,
        }}>
            <RivcreteHeader/>
            <Grid container spacing={1} sx={{m: 2, p: 2}}>
                <Grid item xs={12} sx={{mb: 1}}>
                    <Typography variant='h6'>Not found</Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFound;
