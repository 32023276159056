import {useQuery, useQueryClient} from '@tanstack/react-query';
import type {UseQueryResult} from '@tanstack/react-query';
import {z} from 'zod';
import {convertFromFmToDisplayDate, convertFromFmToDisplayTime, hasTypeOfError} from './Utils';

export type EmployeeSchedule = {
    scheduleDate : string;
    scheduledStartTime : string;
    scheduleUuid : string;
    scheduleViewedOn : string;
    smsMessage : string;
    smsStatus : string;
    smsStatusDetails : string;
    employeeName : string;
    reasonNotAvailable : string;
};

// Type guard for EmployeeSchedule
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
// export const hasTypeOfEmployeeSchedule = (obj : any) : obj is EmployeeSchedule => {
//     return !!obj
//         && typeof obj === 'object'
//         && 'scheduleUuid' in obj;
// };

export const employeeScheduleSchema = z.object({
    scheduleDate: z.string(),
    scheduledStartTime: z.string(),
    scheduleUuid: z.string(),
    scheduleViewedOn: z.string(),
    smsMessage: z.string(),
    smsStatus: z.string(),
    smsStatusDetails: z.string(),
    employeeName: z.string(),
    reasonNotAvailable: z.string(),
});

export type InferredEmployeeSchedule = z.infer<typeof employeeScheduleSchema>;

const useInvalidateEmployeeScheduleCache = async (uuid : string) : Promise<void> => {
    const queryClient = useQueryClient();
    await queryClient.invalidateQueries(['employeeSchedule', uuid]);
};

const fetchEmployeeSchedule = async (route : string) : Promise<InferredEmployeeSchedule> => {
    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;

    try {
        const response = await fetch(`${baseUrl}${route}`);
        const rawData : unknown = await response.json();

        if (!response.ok) {
            console.log('fetchEmployeeSchedule: response:', response);
            console.log('fetchEmployeeSchedule: rawData:', rawData);
            throw new Error(hasTypeOfError(rawData) ? rawData.hint : response.statusText);
        }

        const data = employeeScheduleSchema.safeParse(rawData);

        if (!data.success) {
            throw new Error(data.error.message);
        }

        return data.data;
    } catch (error) {
        throw new Error('Failed to retrieve employee schedule.');
    }
};

// TODO: Is UseQueryResult<Invoice, Error> the right way to type the result of this function?
const useEmployeeScheduleQuery = (uuid : string | undefined) : UseQueryResult<EmployeeSchedule, Error> => {
    return useQuery(
        ['employeeSchedule', uuid],
        async () : Promise<EmployeeSchedule> => {
            if (!uuid) {
                throw new Error('Missing required ID');
            }

            const data = await fetchEmployeeSchedule(`/schedule/${uuid}`);
            // console.log('data.scheduleDate:', data.scheduleDate);
            // console.log('convertFromFmToJsDate(data.scheduleDate):', convertFromFmToJsDate(data.scheduleDate));
            return {
                ...data,
                scheduleDate: convertFromFmToDisplayDate(data.scheduleDate),
                scheduledStartTime: convertFromFmToDisplayTime(data.scheduledStartTime),
            };
        }
    );
};

export {useEmployeeScheduleQuery, useInvalidateEmployeeScheduleCache, fetchEmployeeSchedule};
