import Typography from '@mui/material/Typography';
import classes from './RivcreteHeader.module.scss';
import logo from '@/images/logo.png';

const RivcreteHeader = () : JSX.Element => {
    console.log('classes:', classes);
    return (
        <>
            <Typography variant="body1" sx={{color: 'white', m: 2, pl: 2}}>
                <img src={logo} className={classes.rivcreteLogo}/>
            </Typography>
        </>
    );
};

export default RivcreteHeader;
