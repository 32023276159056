import {DateTime} from 'luxon';

// Ref: https://moment.github.io/luxon/#/parsing?id=table-of-tokens
export const convertFromFmToJsDate = (fmDate : string) : Date => DateTime.fromFormat(fmDate, 'MM/dd/yyyy').toJSDate();

export const convertFromFmToDisplayDate = (fmDate : string) : string => {
    return fmDate === '' ? '' : DateTime.fromFormat(fmDate, 'MM/dd/yyyy').toFormat('DDDD');
};

export const convertFromFmToDisplayTime = (fmTime : string) : string => {
    return fmTime === '' ? '' : DateTime.fromFormat(fmTime, 'hh:mm:ss').toFormat('t');
};

export const formatAsCurrency = (value : number) : string => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
};

type FetchError = {
    hint : string;
    status : number;
    message : string;
};

// Type guard for FetchError
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const hasTypeOfError = (obj : any) : obj is FetchError => {
    return !!obj
        && typeof obj === 'object'
        && 'hint' in obj
        && 'status' in obj
        && 'message' in obj;
};
