import {Grid, Skeleton, Typography} from '@mui/material';
import {Container} from '@mui/system';
import {useParams} from 'react-router';
import AlertWithTitle from '@/components/AlertWithTitle';
import RivcreteHeader from '@/components/RivcreteHeader';
import {useEmployeeScheduleQuery} from '@/utils/EmployeeSchedule';

const Schedule = () : JSX.Element => {
    const {uuid} = useParams();

    if (!uuid) {
        return <AlertWithTitle message="Missing required id."/>;
    }

    const {isLoading, error, data: employeeSchedule} = useEmployeeScheduleQuery(uuid);

    if (error) {
        return <AlertWithTitle message={error.message}/>;
    }

    console.log('employeeSchedule:', employeeSchedule);
    return (
        <Container maxWidth="md" sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 500,
            flexGrow: 1,
        }}>
            <RivcreteHeader/>
            <>
                {isLoading
                    ? <>
                        <Skeleton variant="rectangular" height={100}/>
                        <Skeleton variant="text" height={50}/>
                    </>
                    : <Grid container spacing={1} sx={{m: 1, p: 2}}>
                        <Grid item xs={12} sx={{mb: 1}}>
                            <Typography variant="h3" sx={{mb: 2}}>
                                {employeeSchedule.employeeName}
                            </Typography>
                            <Typography variant="h4">
                                {employeeSchedule.scheduledStartTime === ''
                                    ? <>
                                        {employeeSchedule.reasonNotAvailable} for
                                        <b> {employeeSchedule.scheduleDate}</b>
                                    </>
                                    : <>
                                        Your start time for <b>{employeeSchedule.scheduleDate}</b> is
                                        <b> {employeeSchedule.scheduledStartTime}</b>.
                                    </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </>
        </Container>
    );
};

export default Schedule;
