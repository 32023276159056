import {Alert, AlertTitle} from '@mui/material';
import {Container} from '@mui/system';
import RivcreteHeader from '@/components/RivcreteHeader';

type Props = {
    heading ?: string;
    message : string;
};

const AlertWithTitle = ({heading, message} : Props) : JSX.Element => {
    return (
        <Container maxWidth="md" sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 500,
            flexGrow: 1,
        }}>
            <RivcreteHeader/>
            <Alert severity="error">
                <AlertTitle>{heading ?? 'Error'}</AlertTitle>
                {message}
            </Alert>
        </Container>
    );
};

export default AlertWithTitle;
